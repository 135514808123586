@tailwind base;
@tailwind components;
@tailwind utilities;



/* SHADCN UI */
@layer base {
  :root {
    --background:	224 24% 18%;
    --foreground: 0 0% 100%;
 
    --muted: 223 20% 28%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --popover: 222 22% 21%;
    --popover-foreground: 215 20.22% 65.1%;
 
    --border: 223 20% 28%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 176.5 80.12% 33.53%;
    --primary-foreground: 0 0% 100%;

    --secondary: 220 9% 46%;
    --secondary-foreground: 0 0% 100%;
 
    --accent: 222, 21%, 21%;
    --accent-foreground: 0 0% 100%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;

    /* Old Colors */
    --container: 222 22% 21%;
    --container-light: 223 20% 23%;
    --container-foreground: 215 20.22% 65.1%;
    --primary-light: 172.46 66.01% 50.39%;
  }
 

}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

/* Custom CSS */

body {
  margin: 0;
  font-family: Inter, SF Pro Display, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-white;
  @apply bg-background;
}

.remove-autofill:autofill, .remove-autofill:-webkit-autofill {
  -webkit-box-shadow: 10px 2px 1px 30px rgb(47 54 71) inset !important;
  -webkit-text-fill-color: white !important;
  caret-color: white !important;
  @apply border border-gray-200 border-opacity-100 drop-shadow-lg;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.segoe-ui {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Assuming your heatmap has a class 'heatmap' */
.heatmap rect{
  /* make it rounded */
  rx: 2px;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(47, 54, 71, 0.7)
  }
  100% {
    background-color: rgba(47, 54, 71, 0.2)
  }
}

/* Hide on very small screens */
@media screen and (max-width: 400px) {
  .hide-on-mobile {
    display: none;
  }
}

/* fade out animation */
.fadeOutDelayed {
  animation: fadeOut 0.4s forwards;
  -webkit-animation: fadeOut 0.4s  forwards;
  -moz-animation: fadeOut 0.4s  forwards;
  -o-animation: fadeOut 0.4s forwards;
  -ms-animation: fadeOut 0.4s forwards;
  animation-delay: 1.5s;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes loadBar {
  0% {
    width: 0%;
  }
  30% {
    width: 60%;
  }
  90% {
    width: 90%;
  }
  99% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}

@keyframes disappear {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-bar-full {
  width: 100%;
  animation: disappear 0.5s forwards; /* This will cause the bar to disappear */
}

.loading-bar-animating {
  animation: loadBar 5s ease-in-out forwards; /* Adjust time as needed */
}

.disableActiveLine .cm-activeLine, .disableActiveLine .cm-activeLineGutter {
  background: transparent !important;
  color: inherit !important;
}
